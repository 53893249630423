@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.form {
  max-width: 500px;

  section {
    margin-bottom: $margin-md;
  }

  .row {
    display: flex;
    >div:first-child {
      margin-right: $margin-sm;
    }
  }

  .containerField {
    @include containerField;
    width: 100%;
  }
  label {
    color: $text-light;
  }
  .color {
    margin-top: 10px;
    height: 45px;
    width: 45px;
    position: relative;
    border: solid 1px #EAE8E3;
    border-radius: 10px;
    @include flex-center;
    .select {
      height: 34px;
      width: 34px;
      border-radius: 8px;
    }
    input {
      height: 45px;
      width: 45px;
      border: none;
      opacity: 0;
      position: absolute;
    }
  }
}
