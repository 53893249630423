@import './questions-distribution-variables.scss';

.questionsDistribution {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .inputsContainer {
    margin: 0 0 12px;
    display: flex;
    align-items: flex-end;
    gap: 25px;
  }


  // input type de questions
  .totalQuestionsInput {
    
    label {
      color: $black-50;
    }

    input {
      display: block;
      width: 153px;
      height: 42px;
      background: $green-10;
      border: none;
      border-radius: 14px;
      margin: 10px 0 0;
      outline: none;

      font-size: $extra-large;
      font-weight: 800;
      padding: 0 0 0 20px;
    }
  }

  // container des inputs des (4/2) différents thèmes de questions
  .distributedInputsContainer {
    display: flex;
    gap: 12px;
  }
}