@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.home {
  position: relative;
  @include page();
  
  header {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-md;
    z-index: 2;
    h1 {
      font-size: 36px;
    }
    .filter {
      border-radius: 50px;
      border: 1px solid #EAE8E3;
      background: $white;
      padding: 0px 12px;
    }
  }

  .content {
    position: relative;
    z-index: 0;
    .header {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      margin-bottom: 30px;
      .indicators {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
      .connection {
        padding: 10px 15px 15px 15px;
        border-radius: 11px;
        background: $white;
        box-shadow: 0px 2px 33.7px 0px rgba(0, 0, 0, 0.08);
        h2 {
          margin-bottom: 0px;
        }
        .chart {
          width: 100%;
          height: 185px;
        }
      }
    } 

    .articles {
      .list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
      }
    }

  }

}