$bg-color: #FBFAF9;
$white: #FFFFFF;
$primary-color: #00875E;
$secondary-color: #4EABBF;
$third-color: #4C549E;
$success: #5BC284;
$warn: #FA5C4F;

$white: #FFFFFF;

$border-grey: #EAE8E3;
$light-grey: #949494;
$grey: #404B52;

$black: #282828;
$text-light: #6F6F6F;
$text-color: #282828;

$xl-screen: 1200px;
$lg-screen: 1024px;
$md-screen: 900px;
$sm-screen: 700px;
$xs-screen: 576px;

$margin-xl : 50px;
$margin-lg : 30px;
$margin-md : 20px;
$margin-sm : 15px;
$margin-xs : 10px;


$h1: 24px;
$h2: 20px; 
$small: 12px;
$regular: 14px;
$medium: 18px;
$radius: 10px;

@font-face {
	font-family: "NunitoSans";
	src: url("../assets/fonts/NunitoSans_10pt-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "NunitoSans-Bold";
	src: url("../assets/fonts/NunitoSans_10pt-ExtraBold.ttf") format("truetype");
}

@font-face {
	font-family: "NunitoSans-Italic";
	src: url("../assets/fonts/NunitoSans_10pt-SemiBoldItalic.ttf") format("truetype");
}
