@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.create {
  display: flex;
  flex-wrap: wrap;
  height: 100%;

  .header {
    display: flex;
    width: 100%;
    margin-bottom: $margin-xs;
    padding-left: $margin-sm;

    @media screen and (min-width: $xl-screen) {
      padding-left: 0;
    }

    a {
      text-decoration: none;
      color: $text-light;
      svg {
        position: relative;
        top: 2px;
        margin-right: 5px;
      }
    }

    p {
      font-family: "NunitoSans-Bold";
      margin-left: $margin-xs;
      padding-left: $margin-xs;
      margin-bottom: 0;
      border-left: solid 1px $border-grey;
    }

  }

  .form {
    @include page();
    padding: 0;
    width: calc(100% - 354px);
    overflow: auto;
  }
  
  .moreSettings {
    min-height: 100%;
    width: 354px;
    padding-left: $margin-sm;

    button {
      width: 100%;
      margin-bottom: $margin-sm;
      .dotLoader {
        @include dotLoader();
        background-color: rgba($white, 0.5);
      }
    }
  }
}