@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.form {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  .col {
    display: flex;
    flex-direction: column;
    width: 32%;
    &:first-child {
      width: calc(68% - 20px);
      @include page;
    }
    .creator {
      margin: 0px;
      margin-top: 15px;
      font-size: 12px;
      color: $text-light;
    }
    .name {
      max-width: 420px;
    }
    .tags {
      @include page;
      margin-top: 15px;
      min-height: auto;
      @media screen and (min-width: $xl-screen) {
        padding-bottom: 5px;
      }
    }
    section {
      margin-top: 20px;
      width: 100%;
      .row {
        display: flex;
        .search {
          border: 1px solid $border-grey;
          display: flex;
          align-items: center;
          max-width: 300px;
          margin-bottom: 20px;
          border-radius: 10px;
          svg {
            font-size: 20px;
          }
          input {
            width: calc(100% - 30px);
            border-radius: 10px;
            border: none;
            padding: 10px 15px;
            height: 45px;
            &:focus {
              outline: none;
            }
          }
        }
        button {
          margin-left: 15px;
        }
      }
      .message {
        span {
          color: $primary-color;
          text-decoration: underline;
        }
      }
      h2 {
        font-size: 14px;
      }
      .list {
        width: 100%;
        margin-bottom: 30px;
        border-radius: 10px;
        overflow: hidden;
        &:first-child {
          .item {
            background-color: rgba(#368F65, 0.5);
          }
        }
        .item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-items: center;
          min-height: 40px;
          padding: 0px 15px;
          &:nth-child(odd) {
            background-color: rgba($border-grey, 0.3);
            &:not(:last-child) {
              border-bottom: 1px solid $border-grey;
            }
          }
          &.add {
            background-color: rgba(#368F65, 0.2);
            &:not(:last-child) {
              border-bottom: 1px solid rgba(#368F65, 0.2);
            }
          }
          p {
            margin: 0px;
          }
          button {
            padding: 0px;
            background-color: transparent;
            border: none;
            height: auto;
            font-size: 12px;
            &.delete {
              color: $warn;
              text-decoration: underline;
            }
            &.add {
              color: $primary-color;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
