@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.container {
  label {
    display: block;
    margin-bottom: 10px;
    margin-left: 10px;
    color: $text-light;
  }
  .form {
    display: flex;
    .field {
      width: 260px;
    }
    .color {
      margin: 0px 10px;
      height: 45px;
      width: 45px;
      position: relative;
      border: solid 1px #EAE8E3;
      border-radius: 10px;
      @include flex-center;
      .select {
        height: 34px;
        width: 34px;
        border-radius: 8px;
      }
      input {
        height: 45px;
        width: 45px;
        border: none;
        opacity: 0;
        position: absolute;
      }
    }
    button {
      display: flex;
      align-items: center;
      p {
        margin: 0px 0px 0px 10px;
        color: $white;
      }
    }
  }
}