@import './_variables.scss';

@mixin h1 {
  font-size: $h2;
  // line-height: calc($h2 + 2px);
  color: $text-color;
  font-weight: 600;

  @media screen and (min-width: $md-screen) {
    font-size: $h1;
    // line-height: calc($h1 + 2px);
  }
}

@mixin h2 {
  font-size: 30px;
  color: $text-color;
  text-transform: uppercase;
  font-weight: 600;

  @media screen and (min-width: $md-screen) {
    font-size: $h2;
  }
}

@mixin bold {
  font-family: "NunitoSans-Bold";
}

@mixin content-max {
  max-width: 1440px;
  width: 100%;
  padding: 0 $margin-sm;
  margin: 0 auto;

  @media screen and (min-width: $xs-screen) {
    padding: 0 $margin-md;
  }

  @media screen and (min-width: $sm-screen) {
    padding: 0 $margin-lg;
  }
}

@mixin content {
  max-width: 1240px;
  width: 100%;
  padding: 0 $margin-sm;
  margin: 0 auto;

  @media screen and (min-width: $xs-screen) {
    padding: 0 $margin-md;
  }
}

@mixin content-large {
  max-width: 1700px;
  width: 100%;
  margin: 0 auto;
}

@mixin containerField {
  margin-bottom: $margin-md;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($black, 0.2);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin page() {
  border-radius: $radius;
  background-color: $white;
  min-height: 100%;
  padding: $margin-sm $margin-md;

  @media screen and (min-width: $xl-screen) {
    padding: $margin-lg $margin-lg;
  }

}

@mixin rowsCount() {
  display: inline-block;
  font-size: $regular;
  color: $text-light;
  border: solid 1px $border-grey;
  padding: 3px 5px;
  margin-left: $margin-sm;
  vertical-align: middle;
  border-radius: 5px;
}

@mixin remove-highlight() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}


@mixin dotLoader($colorDot: $primary-color) {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: $colorDot;
  border-radius: 7px;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-name: rotate;
  margin-right: $margin-sm;
}

@mixin backgroundImage($url) {
  content: '';
  position: absolute;
  z-index: 0;
  top: 90px;
  right: $margin-lg;
  display: block;
  width: 100%;
  height: calc(100% - 140px);
  max-height: 570px;
  background-repeat: no-repeat;
  background-image: url($url);
  background-position: center right;
  background-size: contain;
  pointer-events: none;
}

@keyframes rotate {
  0% {
    transform: rotateX(0) rotateY(0);
  }
  50% {
    transform: rotateX(-180deg) rotateY(0);
  }
  100% {
    transform: rotateX(-180deg) rotateY(-180deg);
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}