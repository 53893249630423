@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.articlesList {
  @include page();

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-md;

    h1 {
      margin-bottom: 0;
      span {
        @include rowsCount();
      }
    }

    >div:last-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      a {
        text-decoration: none;
      }

      button {
        margin-left: $margin-md;
      }
    }
    
  }

  .filters {
    display: flex;
    margin-bottom: $margin-xs;
    >div {
      margin-right: $margin-md;
    }
  }

  .panel {
    border-bottom: solid 1px $border-grey;
    .panel-header {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        margin: 0px;
      }
      .actions {
        display: flex;
        align-items: center;
        p {
          margin: 0px 10px;
          font-family: "NunitoSans-Bold", sans-serif;
          @include rowsCount();
        }
        button {
          background-color: transparent;
          border: none;
          padding: 0px;
          width: 40px;
          height: 40px;
          color: $black;
          font-size: 23px;
          @include flex-center;
          &.open {
            transform: rotate(180deg);
          }
        }
      }
    }
    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      margin-bottom: 20px;
    }
  }
}

.card {
  display: block;
  text-decoration: none;
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
  &.home {
    padding: 4px;
    border-radius: 11px;
    background: $white;
    box-shadow: 0px 2px 33.7px 0px rgba(0, 0, 0, 0.08);
    .infos {
      padding: 10px 10px 10px 10px;
      p {
        -webkit-line-clamp: 2;
        max-height: calc(1.5em * 2);
      }
    } 
  }
  .cover {
    height: 135px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #efeeee;
    img {
      width: 100%;
      @include transition;
      object-fit: cover;
    }
  }
  .infos {
    .tags {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 3px;
      margin-top: 3px;
      p {
        padding: 3px 10px;
        margin: 5px 5px 5px 0px ;
        border-radius: 30px;
        font-size: 12px;
        border: 1px solid #EAE8E3;
        color: $black;
      }
    }
    h3 {
      font-size: 18px;
      margin-bottom: 0px;
    }
    p {
      font-size: 12px;
      color: $text-light;
      display: -webkit-box;         
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5;
      max-height: calc(1.5em * 3);
      margin-bottom: 0px;
      &.creator {
        margin-top: 0px;
        margin-bottom: 0px;
        color: $primary-color;
      }
    }
  }
}