@import "../../styles/_variables.scss";
@import "../../styles/_mixins";
.container {
  width: 80vw;
  max-width: 800px;
  padding: 10px 20px;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      margin: 0px;
    }
    .tabs {
      display: flex;
      button {
        background-color: transparent;
        color: $text-light;
        @include bold;
        padding: 0px;
        border: none;
        display: block;
        margin-left: 20px;
        font-size: 14px;
        span {
          margin-top: 2px;
          display: block;
          height: 5px;
          border-radius: 3px;
          width: 100%;
          @include transition;
        }
        &.active {
          color: $text-color;
          span {
            background-color: $primary-color;
          }
        }
      }
    }
  }
  .search {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    .input {
      border: 1px solid $border-grey;
      border-radius: 10px;
      overflow: hidden;
      width: 280px;
      position: relative;
      input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 14px;
        padding: 11px 14px;
      }
      .icon {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 41px;
        @include flex-center;
        color: $black;
        width: 40px;
        font-size: 18px;
        pointer-events: none;
        &.active {
          pointer-events: all;
          cursor: pointer;
        }
      }
    }
    .filters {
      display: flex;
      > div {
        margin-right: 10px;
      }
    }
  }
  .add {
    height: 45px;
    button {
      padding: 0px 10px;
    }
  }
  .list {
    padding-top: 8px;
    height: 30vh;
    overflow-y: auto;
  }
  .row {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 36px;
    &.label {
      margin-top: 20px;
      border-bottom: 1px solid $border-grey;
      p {
        padding-bottom: 5px;
        color: $text-light;
      }
    }
    .col {
      width: calc(50% - 15px);
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
      .check {
        @include transition;
        background-color: rgba($text-light, 0.2);
        width: 20px;
        height: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: rgba($text-light, 0.4);
        }
        svg {
          color: $primary-color;
        }
      }
      &:first-child {
        width: 30px;
      }
      p {
        margin: 0px;
      }
      &:not(.tags) {
        p {
          margin: 0px 8px 0px 0px;
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    .shared {
      display: flex;
      p {
        margin: 0px;
        margin-right: 10px;
        @include bold;
        span {
          color: $primary-color;
        }
      }
    }
    .actions {
      display: flex;
      align-items: center;
    }

  }
  .cancel {
    background-color: transparent;
    color: $text-light;
    @include bold;
    border: none;
    text-decoration: underline;
  }
}