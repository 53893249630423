@import '../questions-distribution-variables.scss';

.distributedInput {
  display: flex;
  gap: 12px;
  align-items: flex-end;
  position: relative;
  p {
    padding: 0 0 0 10px;
    font-size: 12px;
    svg {
      transform: translateY(3px);
      font-size: 16px;
      margin-left: 5px;
      color: #D4D4D4;
    }
  }

  input {
    display: block;
    width: 100%;
    height: 43px;
    border: 1px solid $stroke-100;
    border-radius: 10px;
    margin: 10px 0 0;

    font-size: $large;
    padding: 0 0 0 20px;
    &:focus {
      outline: 1px solid $green-120;
    }
  }

    // hide arrows of inputs type number
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
    }

    input[type=number] {
      appearance:textfield; /* Firefox */
    }
    // hide arrows of inputs type number
}
