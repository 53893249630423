@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.edit {
  position: relative;
  @include page();
  display: flex;
  flex-direction: column;
  height: 100%;

  // background-repeat: no-repeat;
  // background-image: url('../../../assets/images/illu-tepik-teams.svg');
  // background-position: center right;
  // background-size: contain;

  &:after {
    @include backgroundImage('../../../assets/images/illu-tepik-teams.svg');
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-md;

    .dotLoader {
      @include dotLoader(rgba(255, 255, 255, 0.4));
    }

    .backward {
      font-size: 24px;
    }
    
  }

  .form {
    position: relative;
    z-index: 1;
  }

  .delete {
    font-family: 'NunitoSans-Bold';
    color: $warn;
    text-decoration: underline;
    cursor: pointer;
    margin: auto $margin-md 0 0;
  }

}