@import "./_variables.scss";
@import "./_mixins.scss";

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $bg-color;
  font-family: "NunitoSans", sans-serif;
  font-size: $regular;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

.App {
  height: 100%;
}

.italic {
  font-style: italic;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, p {
  margin-top: 0,
}

h1, h2 {
  margin-bottom: $margin-sm;
}

h3, h4 {
  margin-bottom: $margin-xs;
}

h1 {
  font-family: "NunitoSans-Bold", sans-serif;
}

h2 {
  font-family: "NunitoSans-Bold", sans-serif;
  font-size: $medium;
}

h4 {
  font-family: "NunitoSans-Bold", sans-serif;
  font-size: $regular;
}

p,
a,
li,
input,
textarea {
  font-family: "NunitoSans", sans-serif;
  color: $text-color;
  font-size: $regular;
  font-weight: normal;
  &.bold {
    font-family: "NunitoSans-Bold", sans-serif;
  }
  &.small {
    font-size: $small;
  }
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  font-size: $regular;
  font-family: "NunitoSans", sans-serif;
  background-color: $primary-color;
  padding: $margin-sm $margin-md;
  color: white;
  border: solid 1px $primary-color;
  border-radius: $radius;
  text-align: center;
  white-space: nowrap;
  height: 45px;
  cursor: pointer;
  box-shadow: 0px 14px 64px -4px rgba(24, 39, 75, 0.04)

  span {
    vertical-align: middle;
  }

  .icon {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    // height: 0px;

    &.loader > div {
      position: relative;
      top: -1px;
    }

    svg {
      position: relative;
      top: -50%;
      vertical-align: middle;
    }
  }

  .icon:not(.loader):first-child {
    margin-left: -8px;
  }

  // span:first-child:not(.icon),
  // .icon + span {
  //   position: relative;
  //   top: -1px;
  // }

  span + .icon,
  .icon + span {
    margin-left: 8px;
  }

  &.secondary {
    background-color: $white;
    color: $primary-color;
    svg {
      color: $primary-color;
    }
  }

  &.invisible {
    background-color: transparent;
    border-color: transparent;
    color: $light-grey;
  }

  &.disabled {
    opacity: 0.7;
    background-color: #EAEAEA;
    border-color: #EAEAEA;
    color: $text-light;
  }
}

a {
  text-underline-offset: 2px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px transparent inset !important;
}

button {
  &.loader {
    position: relative;
    top: -1px;
  }
}