@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.modalPlants {
  width: 310px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-sm;

    h5, p {
      font-size: $small;
      margin: 0;
    }

    p {
      color: $primary-color;
      cursor: pointer;
    }

  }

  .containerBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: $margin-sm;
  }

}

hr {
  margin-top: 20px;
  border: none;
  width: calc(100% + 60px);
  margin-left: -30px;
  border-bottom: dashed 1px $light-grey;
}

.form {
  .containerField {
    @include containerField;
  }
  .tabs {
    margin: 0 calc(-1 * $margin-lg);
    ul {
      padding: 0;
      margin: 0;
      border-bottom: solid 1px #EAEAEA;
      li {
        position: relative;
        display: inline-block;
        list-style: none;
        font-family: NunitoSans-Bold;
        font-size: $medium;
        padding: 0 $margin-md $margin-sm $margin-md;
        cursor: pointer;
        &.actived:after {
          content: '';
          position: absolute;
          display: block;
          width: 100%;
          height: 5px;
          background-color: $primary-color;
          bottom: 0;
          left: 0;
          transform: translateY(100%);
          border-radius: 0 0 2px 2px;
        }
      }
    }
  }

  .advancedModeButton {
    padding: 5px $margin-sm;
    height: auto;
  }

  section {
    padding: 0px;
    // &:not(:last-child) {
    //   border-bottom: dashed 1px $light-grey;
    // }
  }

  .questionsCount {
    margin: 15px 0px 30px 0px;
    .count-container {
      margin-bottom: 25px;
      .count {
        display: inlin-block;
        font-family: NunitoSans-Bold;
        background-color: rgba(54, 143, 101, 0.19);
        font-size: $h2;
        padding: 5px $margin-xs;
        border-radius: $radius;
      }
    }

    >div {
      margin-bottom: $margin-md;
    }

    .fields {
      display: flex;
      margin-bottom: 0px;
      .containerField:not(:last-child) {
        margin-right: $margin-xs;
      }
    }
  }

  .recognition h4 span {
    background-color: $secondary-color;
  }
  .knowledge h4 span {
    background-color: $third-color
  }

  .recognition,
  .knowledge {

    h5 {
      font-family: NunitoSans-Bold;
      font-size: $regular;
    }

    .distribution {
      display: flex;
      .containerField {
        max-width: 130px;
      }
      .containerField:not(:last-child) {
        margin-right: $margin-xs;
      }
    }

    .selectionPlants {
      max-width: 600px;
      cursor: not-allowed;
      opacity: 0.35;
      >* {
        pointer-events: none;
      }
    }
  
    .selectFiltersPlant {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .containerField {
        width: calc(33.33% - 10px)
      }
    }

    .specificPlants {
      ul {
        margin: $margin-sm 0 0 0;
        padding: 0;
        li {
          display: inline-block;
          list-style-type: none;
          margin: 0 $margin-xs $margin-xs 0;
          &:not(:last-child) {
            margin-bottom: $margin-xs;
          }
          .plant {
            background-color: $third-color;
            padding: 3px 10px;
            height: 30px;
            border-radius: $radius;
            max-width: 150px;
            p {
              margin: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              color: $white;
            }
          }
        }
      }
    }

  }
  

  .plants {
    margin-top: 20px;
    .choose {
      display: flex;
      button {
        margin-right: 15px;
        &:not(.active) {
          color: #6F6F6F;
          background-color: transparent;
        }
      }
    }
    .selectPlants {
      margin-bottom: 15px;
      .select {
        display: flex;
        align-items: flex-end;
        width: 100%;
        .containerField {
          width: 50%;
          margin-right: 15px;
          margin-bottom: 0px;
          button {
            &.create {
              background-color: transparent;
              border: none;
              padding: 0px;
              color: $primary-color;
              height: auto;
              text-decoration: underline;
            }
          }
        }
      }
    }
    .typesPlantName {
      max-width: 600px;
      margin-bottom: 35px;
    }
  }
  
  .difficulty {
    margin-top: 20px;
    p {
      color: $light-grey;
    }
    .select {
      display: flex;
      margin-bottom: 20px;
      .level {
        margin-right: 15px;
        width: 26px;
        height: 26px;
        border-radius: 20px;
        padding: 0px;
        @include flex-center;
        text-transform: uppercase;
      }
    }
  }   
}