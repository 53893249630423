@import "../../styles/_variables.scss";

.counter {
  padding: 20px;
  border-radius: 11px;
  background: $white;
  box-shadow: 0px 2px 33.7px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  .label {
    display: flex;
    .icon {
      margin-right: 8px;
    } 
  }
  .values {
    display: flex;
    align-items: flex-end;
    h2 {
      margin: 0px;
      font-size: 32px;
      line-height: 20px;
    }
    h4 {
      margin: 0px 10px;
      color: #00875E;
      line-height: 14px;
      transform: translateY(2px);
    }
  }
}