@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.form {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  .col {
    display: flex;
    flex-direction: column;
    width: 32%;
    padding-right: 20px;
    padding-bottom: 50px;
    justify-content: flex-start;
    &:first-child {
      width: calc(68% - 20px);
      @include page;
    }
    .submit {
      &.saved {
        background-color: #949494;
        border: 1px solid #949494;
      }
    }
    .creator {
      margin: 0px;
      margin-top: 15px;
      font-size: 12px;
      color: $text-light;
    }
    .description {
      margin-bottom: 20px;
    }
    .name {
      margin-bottom: 20px;
    }
    .block {
      @include page;
      margin-top: 15px;
      min-height: auto;
      @media screen and (min-width: $xl-screen) {
        padding: 20px 30px;
        padding-bottom: 5px;
      }
    }
    label {
      color: $text-light;
    }
    .date {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .input {
        width: calc(100% - 150px);
      }
    }
    .cover {
      background-color: #f8f8f8;
      width: 100%;
      height: 25vh;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: dashed 1px rgba($black, 0.3);
      color: $primary-color;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      &.media {
        border: 1px solid  #EAE8E3;
      }
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
      svg {
        font-size: 24px;
        margin-right: 10px;
      }
      p {
        color: $primary-color;
        font-family: "NunitoSans-Bold";
        margin: 0px;
      }
    }
    .delete {
      background-color: transparent;
      border: none;
      text-align: left;
      font-family: 'NunitoSans-Bold';
      color: $warn;
      text-decoration: underline;
      cursor: pointer;
      margin: $margin-md 0px;
    }
  }
}

.add {
  width: 100%;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: dashed 1px rgba($black, 0.3);
  position: relative;
  .add-btn {
    padding: 0px;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    &:not(.open) {
      background-color: #949494;
      border: 1px solid #949494;
    }
  }
  .option-container {
    position: absolute;
    height: 240px;
    width: 110px;
    bottom: 0px;
    pointer-events: none;
    opacity: 0;
    @include transition;
    &.open {
      pointer-events: all;
      opacity: 1;
    }
    .options {
      height: calc(45px * 4);
      position: relative;
      border-radius: 4px;
      border: 1px solid  #EAE8E3;
      background: #FFF;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.03);
      overflow: hidden;
      button {
        height: 45px;
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $black;
        padding: 8px 15px;
        cursor: pointer;
        width: 100%;
        border-radius: 0px;
        &:hover {
          background-color: rgba($black, 0.1);
        }
        .icon {
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        p {
          margin: 0px 10px;
          text-align: center;
        }
      }
    }
  }
}

.fields {
  margin: 20px 0px;
  .field {
    position: relative;
    margin-bottom: 15px;
    &:hover {
      .actions {
        opacity: 1;
        pointer-events: all;
      }
    }
    &.dragging {
      opacity: 0.8;
    }
    .actions {
      position: absolute;
      display: flex;
      z-index: 1;
      right: 0px;
      top: 0px;
      opacity: 0;
      background-color: $primary-color;
      pointer-events: none;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
      height: 30px;
      width: 56px;
      .icon {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        cursor: pointer;
        &:last-child {
          cursor: grab;
        }
      }
    }
    .cover {
      margin-top: 0px;
      &.media {
        height: auto;
        video {
          width: 100%;
        }
      }
    }
    .input {
      border: 1px solid  #EAE8E3;
      border-radius: 10px;
      padding: 10px 15px 5px 15px;
    }
  }
}