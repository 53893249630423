@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.block {
  border-radius: $radius;
  background-color: $white;
  padding: $margin-xs $margin-sm;
  margin-top: 20px;
  .picture {
    background-color: #f8f8f8;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: dashed 1px rgba($black, 0.3);
    color: $primary-color;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    &.media {
      border: 1px solid  #EAE8E3;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    svg {
      font-size: 24px;
      margin-right: 10px;
    }
    p {
      color: $primary-color;
      font-family: "NunitoSans-Bold";
      margin: 0px;
    }
  }
  
}