@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.createCenter {
  position: relative;
  @include page();
  @media screen and (min-width: $xl-screen) {
    padding: 0px;
  }
  &:after {
    @include backgroundImage('../../../assets/images/illu-tepik-teams.svg');
  }

  .submit {
    position: absolute;
    right: 0px;
    z-index: 2;
  }
  

  .content {
    position: relative;
    z-index: 1;
  }

}