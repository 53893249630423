@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_mixins.scss';

.createMember {
  position: relative;
  border-radius: $radius;
  background-color: $white;
  min-height: 100%;
  padding: $margin-sm $margin-md;

  &:after {
    @include backgroundImage('../../../../../assets/images/illu-orga-member.svg');
  }

  .save {
    position: absolute;
    z-index: 2;
    right: 0;

    .dotLoader {
      @include dotLoader();
    }

    h1 {
      margin-bottom: 0;
      span {
        @include rowsCount();
      }
    }
    
  }

  .form {
    position: relative;
    z-index: 1;
  }

}