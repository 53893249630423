@import "../../styles/_variables.scss";
@import "../../styles/_mixins";
.container {
  width: 80vw;
  max-width: 800px;
  .filters {
    display: flex;
    > div {
      margin-right: 10px;
    }
  }
  .list {
    padding-top: 8px;
    height: 30vh;
    overflow-y: auto;
  }
  .row {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 36px;
    &.label {
      margin-top: 20px;
      border-bottom: 1px solid $border-grey;
      p {
        padding-bottom: 5px;
        color: $text-light;
      }
    }
    .col {
      width: calc(50% - 15px);
      .check {
        @include transition;
        background-color: rgba($text-light, 0.2);
        width: 20px;
        height: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: rgba($text-light, 0.4);
        }
        svg {
          color: $primary-color;
        }
      }
      p {
        margin: 0px;
      }
      &:first-child {
        width: 30px;
      }
    }
  }
  .action {
    display: flex;
    justify-content: flex-end;
  }
}