@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.tags {
  display: flex;
  justify-content: flex-start;
  .tag {
    padding: 4px 8px;
    margin: 0px;
    margin-right: 8px;
    border-radius: 5px;
    font-size: 14px;
    background-color: rgb(179, 219, 207);
  }
}