@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.modalPlants {
  width: 493px;
  .field {
    margin-bottom: 10px;
  }
  .actions {
    margin-top: 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    p {
      margin: 0px 10px;
      color: rgba($black, 0.5)
    }
    button {
      &.disabled {
        pointer-events: none;
      }
    }
    .dotLoader {
      @include dotLoader();
      background-color: rgba($white, 0.5);
    }
  }
}