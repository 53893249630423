@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.loader {
  @include flex-center;
  height: 100%;
  width: 100%;
}

.edit {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  padding-right: 20px;
  .form {
    @include page();
    padding: 0;
    width: calc(100% - 334px);
    padding: 20px 30px;
  }
  
  .moreSettings {
    min-height: 100%;
    width: 334px;
    padding-left: $margin-sm;
    position: sticky;
    top: 0;
    height: 100vh;
    button {
      &.not-dirty {
        background-color: #EAEAEA;
        color: #6F6F6F;
        border: 1px solid #EAEAEA;
      }
    }
    .switchMode {
      display: flex;
      margin-bottom: $margin-md;
      a {
        display: block;
        background-color: #EAEAEA;
        width: 50%;
        padding: $margin-sm $margin-md;
        height: 48px;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        &.actived {
          background-color: $primary-color;
          color: $white;
        }
        
        &:first-child {
          border-radius: $radius 0 0 $radius;
        }
        &:last-child {
          border-radius: 0 $radius $radius 0;
        }
      }
    }

    .block {
      border-radius: $radius;
      background-color: $white;
      padding: $margin-xs $margin-sm;

      .containerField {
        @include containerField;
      }

    }

    .lastUpdate {
      .status {
        p {
          font-size: 14px;
          margin-bottom: 3px;
          span {
            @include bold;
            color: $primary-color;
          }
        }
      }
      p {
        font-size: $small;
        color: $text-light;
      }
    }

    button {
      width: 100%;
      margin-bottom: $margin-sm;
      .dotLoader {
        @include dotLoader();
        background-color: rgba($white, 0.5);
      }
    }

    .delete {
      display: inline-block;
      font-family: 'NunitoSans-Bold';
      padding: 0;
      color: $warn;
      text-decoration: underline;
      text-align: left;
      margin-top: $margin-sm;
    }

  }
}