@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input {

  &.unit {
    position: relative;
    input {
      padding-right: 10px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .unit {
      position: absolute;
      top: 50%;
      right: 15px;
      color: $light-grey;
      font-weight: bold;
      transform: translateY(-50%);
    }
  }

  label {
    @include label;
  }
  
  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 10px;
    }
  }
  
  input{
    @include input();
  }

  &.discret input {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border: 0;
  }

  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }
  
  
}
