@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.block {
  border-radius: $radius;
  background-color: $white;
  padding: $margin-xs $margin-sm;

  .containerField {
    @include containerField;
    &.time {
      max-width: 180px;
    }
    // &.lang {
    //   cursor: not-allowed;
    //   opacity: 0.35;
    //   margin-bottom: 0;
    //   >* {
    //     pointer-events: none;
    //   }
    // }
  }
  
}