@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.row {
  display: flex;
  max-width: 600px;
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    .containerField:first-child {
      min-width: 450px;
      width: 450px;
      margin-right: $margin-sm;
    }
  }
}

.row2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $margin-sm;
  max-width: 600px;
}

.companyFields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $margin-sm;
  max-width: 600px;
}

.containerField {
  @include containerField;
  width: 100%;
  &.type {
    display: flex;
    align-items: flex-end;
    >div:first-child {
      width: 100%;
    }
    >button {
      margin-left: $margin-md;
    }
  }
}

.profiles {
  width: 100%;
}

.tags {
  display: flex;
  >div {
    margin-bottom: $margin-xs;
    margin-right: $margin-xs;
  }
}

