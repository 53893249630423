@import '../../styles/_variables.scss';

.formationsTable {
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      th {
        position: relative;
        text-align: left;
        padding: $margin-sm 0 $margin-sm 0;
        border-bottom: solid 1px $border-grey;
        color: $text-light;
        cursor: pointer;
        padding-left: $margin-md;
        svg {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        .sortIcons {
          opacity: 0.3;
          >svg:first-child {
            transform: translateY(-80%);
          }
          >svg:nth-child(2) {
            transform: translateY(-20%);
          }
        }

      }
    }

    tbody {
      tr {
        cursor: pointer;
        &:hover {
          .status {
            .dropdown {
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
      
      td {
        padding: $margin-sm 0;
        padding-left: $margin-md;
        .status {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .dropdown {
            opacity: 0;
            pointer-events: none;
            .icon {
              height: 26px;
              width: 20px;
              transform: rotate(90deg);
            }
            .dropdown-content {
              position: absolute;
              opacity: 0;
              pointer-events: none;
              top: 0px;
              right: 0px;
              &.open {
                opacity: 1;
                pointer-events: all;
                z-index: 10;
              }
              .content {
                border-radius: 3px;
                background: #FFF;
                box-shadow: 0px 2px 33.7px 0px rgba(0, 0, 0, 0.08);
                padding: 0px 10px;
                .item {
                  padding: 10px 10px;
                  width: 175px;
                  &:not(:first-child) {
                    border-top: solid 1px #EAE8E3;
                  }
                  p {
                    margin: 0px;
                  }
                }
              }
            }
          }
        }
        p {
          &.statusLabel {
            position: relative;
            margin: 0px;
            &::after {
              content: '';
              position: absolute;
              top: 50%;
              left: -15px;
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 10px;
              transform: translateY(-50%);
              background-color: $primary-color;
            }
            &.close {
              &::after {
                background-color: $light-grey;
              }
            }
          }
        }

        .countUsers {
          font-family: 'NunitoSans-Bold';
          margin: 0;
        }

        .userCategories {
          padding: 0;
          margin: 0;
          list-style: none;
          li {
            display: inline-block;
            border-radius: 3px;
            padding: 3px $margin-xs;
            background-color: #E5F3EF;
            margin-right: $margin-xs;
            margin-bottom: $margin-xs;
          }
        }

      }
    }

  }
}