@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.mobile {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    font-size: 14px;
  }
}