@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{

  label {
    @include label;
  }
  
  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 10px;
    }
  }
  
  input{
    @include input();
  }

  &.h1 {
    input {
      font-size: 18px;
      padding: 0px 0px 6px 0px;
      border: none;
      border-radius: 0px;
      border-bottom: solid 1px $border-grey;
      font-family: "NunitoSans-Bold";
      &:focus {
        border-bottom: solid 1px $primary-color;
      }
  
      &::placeholder {
        color: $text-light;
        font-family: "NunitoSans-Bold";
      }
    }
  }

  &.discret input {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    border: 0;
  }

  &.primary {
    @include primary;
  }
  &.login {
    @include login;
  }
  
  
}
