@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.stats {
  position: relative;
  @include page();
  
  header {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    h1 {
      font-size: 36px;
    }
    .filter {
      border-radius: 50px;
      border: 1px solid #EAE8E3;
      background: $white;
      padding: 0px 12px;
    }
  }

  .filters {
    margin-bottom: $margin-md;
    display: flex;
    .filter {
      margin-right: 10px;
    }
  }

  .content {
    position: relative;
    z-index: 0;
    width: 80%;
    .indicators {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    .formations {
      margin: 20px 0px;
      padding: 15px 15px 15px 15px;
      border-radius: 11px;
      background: $white;
      box-shadow: 0px 2px 33.7px 0px rgba(0, 0, 0, 0.08);
      width: 100%;
      .row {
        display: flex;
        &:first-child {
          p {
            margin: 0px;
          }
        }
        &:not(:first-child) {
          height: 38px;
          align-items: center;
          border-bottom: 1px solid #EAE8E3;
          p {
            @include bold;
            padding-right: 10px;
            margin: 0px;
          }
        }
        .col {
          width: 25%;
          display: flex;
          align-items: center;
          svg {
            margin-right: 8px;
          }
          &:first-child {
            width: 50%;
          }
        }
      }
    } 

    .articles {
      .list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
      }
    }

  }

}