@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.detail {
  display: flex;
  flex-direction: column;
  .navigation {
    display: flex;
    align-items: center;
    margin-bottom: $margin-md;
    position: relative;
    .backward {
      position: absolute;
      top: 0px;
      display: inline-block;
      font-family: 'NunitoSans';
      text-decoration: none;
      color: $text-light;
      font-size: $regular;
      padding-right: $margin-sm;
      margin-right: $margin-lg;
      border-right: solid 1px $border-grey;
      svg {
        position: relative;
        top: 2px;
        margin-right: 5px;
      }
    }
  }
}