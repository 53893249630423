@import "../../../styles/_variables.scss";
@import "../_form-mixin.scss";

.input {
  display: flex;
  align-items: flex-end;
  
  label {
    @include label;
    +input {
      margin-top: 10px;
    }
  }

  .inputColor {
    all: unset;
    height: 0;
    width: 0;
    padding: 0;
    border: none;
    outline: none;
    box-shadow: none;
    display: inline;
    opacity: 0;
  }

  .inputText {
    @include input();
    max-width: 78px;
    min-width: 90px;
  }

  .color {
    width: 44px;
    height: 44px;
    border-radius: $radius;
    box-shadow: inset 0 0 0 1px rgba(110, 113, 119, 0.3);
    border-radius: 10px;
    margin-left: $margin-xs;
  }

  // &.secondary {
  //   @include secondary;
  //   .color {
  //     width: 37px;
  //     height: 37px;
  //   }
  //   .inputText {
  //     min-height: inherit;
  //     height: 37px;
  //   }
  // }
}
