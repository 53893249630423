@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.listSessions {
  @include page();
  padding: 0px;
  @media screen and (min-width: 1200px) {
    padding: 0px;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: $margin-md;

    h1 {
      margin-bottom: 0;
      span {
        @include rowsCount();
      }
    }

    >div:last-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      a {
        text-decoration: none;
      }

      button {
        margin-left: $margin-md;
      }
    }
    
  }

  .filters {
    display: flex;
    margin-bottom: $margin-xs;
    >div {
      margin-right: $margin-md;
    }
  }

}