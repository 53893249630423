@import "../../../styles/_variables.scss";

.modal {
  min-width: 350px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-sm;
  
    h5, p {
      font-size: $small;
      margin: 0;
    }
  
    p {
      color: $primary-color;
      cursor: pointer;
    }
  
  }
  
  .containerBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: $margin-sm;

    .loader {
      position: relative;
      top: 3px;
      margin-right: $margin-xs;
    }

  }  
}

.btn {
  padding: 5px 8px 5px $margin-sm;
  height: auto;
  span {
    position: relative;
    top: 2px;
    display: inline-block;
    margin-left: 5px;
  }
}