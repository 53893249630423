@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.home {
  position: relative;
  @include page();
    &:after {
    @media screen and (min-width: 1600px) {
      @include backgroundImage('./images/stats.svg')
    }
  }
  header {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    h1 {
      font-size: 36px;
    }
    .filter {
      border-radius: 50px;
      border: 1px solid #EAE8E3;
      background: $white;
      padding: 0px 12px;
    }
  }

  .filters {
    margin-bottom: $margin-md;
    display: flex;
    .filter {
      margin-right: 10px;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    @media screen and (min-width: $md-screen) {
      width: 70%;
      max-width: 650px;
    }
    .indicators {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    .connection {
      margin: 20px 0px;
      padding: 10px 15px 15px 15px;
      border-radius: 11px;
      background: $white;
      box-shadow: 0px 2px 33.7px 0px rgba(0, 0, 0, 0.08);
      width: 100%;
      h2 {
        margin-bottom: 0px;
      }
      .chart {
        width: 100%;
        height: 235px;
      }
    } 

    .articles {
      .list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
      }
    }

  }

}