@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.createAdmin {
  position: relative;
  @include page();

  &:after {
    @include backgroundImage('../../../assets/images/illu-tepik-teams.svg');
  }


  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-md;

    .dotLoader {
      @include dotLoader(rgba(255, 255, 255, 0.4));
    }

    .backward {
      font-size: 24px;
    }
    
  }

  .form {
    position: relative;
    z-index: 1;
  }

}