@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.editSession {
  position: relative;

  .actions {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0px;
    z-index: 2;
    button {
      margin-left: 10px;
    }
    p {
      margin: 0px 10px;
    }
    .cancel {
      background-color: rgba($warn, 0.2);
      color: $warn;
      border: none;
    }
    .count {
      display: flex;
      align-items: center;
      color: $primary-color;
      @include bold;
      margin-right: 10px;
      span {
        height: 30px;
        padding: 8px 10px;
        background-color: rgba($primary-color, 0.1);
        @include bold;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        border-radius: 10px;
      }
    }
  }


  .content {
    position: relative;
    z-index: 1;
  }

}