$extra-large: 20px;
$large: 16px;
$regular: 14px;
$small: 12px;

$white: #FFFFFF;

$black-100: #282828;
$black-50: #6F6F6F;
$black-30: #949494;
$black-10: #EAEAEA;

$green-120: #00714E;
$green-50: #80C3AE;
$green-30: #B3DBCF;
$green-10: #E5F3EF;


$stroke-100: #EAE8E3;
$stroke-50: #FBFAF9;