@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.create-session {
  position: relative;
  @include page();
  .backward {
    margin-bottom: 30px;
    display: inline-block;
    font-family: 'NunitoSans';
    text-decoration: none;
    color: $text-light;
    font-size: $regular;
    padding-right: $margin-sm;
    margin-right: $margin-lg;
    border-right: solid 1px $border-grey;
    svg {
      position: relative;
      top: 2px;
      margin-right: 5px;
    }
  }
  .submit {
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 2;
  }


  .content {
    position: relative;
    z-index: 1;
  }

}  