@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.form {
  max-width: 680px;

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $margin-sm;
    &.organisation {
      width: 100%;
      display: flex;
      .containerField {
        width: 65%
      }
    }
  }

  .containerField {
    @include containerField;
  }

}

