@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.tag {
  position: relative;
  z-index: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color, #B3DBCF);
  color: $text-color;
  font-size: $regular;
  height: 30px;
  border-radius: 4px;
  overflow: hidden;

  >p {
    margin: 0;
    padding: 0 $margin-xs;
    min-width: 50px;
    text-align: center;
  }

  .delete {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      display: block;
      height: 30px;
      width: 30px;
      opacity: 0.12;
      background-color: $black;
    }
    p {
      position: relative;
      z-index: 1;
      margin: 0;
      font-size: $medium;
      cursor: pointer;
    }
  }
}