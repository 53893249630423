@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.editOrganisation {
  position: relative;

  &:after {
    @include backgroundImage('../../../../assets/images/illu-orga.svg');
  }

  .header {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-md;

    h2 {
      margin-bottom: 0;
    }

    >div:last-child {
      margin-left: auto;

      button {
        margin-left: auto;
      }
    }
    
  }


  .content {
    position: relative;
    z-index: 1;
  }

}