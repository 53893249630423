@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.quiz {
 .header {
   display: flex;
   justify-content: space-between;
   .filters {
     display: flex;
     align-items: center;
     > div {
       margin-right: 10px;
     }
   }
 }
}