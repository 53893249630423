@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.row {
  display: flex;
  max-width: 600px;
}

.row2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $margin-sm;
  max-width: 600px;
}

.companyFields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $margin-sm;
  max-width: 600px;
}

.containerField {
  @include containerField;
  width: 100%;
}
