@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.listTags {
  @include page();

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-md;

    h1 {
      margin-bottom: 0;
      span {
        @include rowsCount();
      }
    }

    >div:last-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      a {
        text-decoration: none;
      }

      button {
        margin-left: $margin-md;
      }
    }
    
  }

  .tags {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    .tag {
      border-radius: 10px;
      margin: 0px 10px 10px 0px;
      padding: $margin-sm;
      cursor: pointer;
      transition: all .3s ease-in-out;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 6px 14px;
      &:hover {
        background-color: $bg-color;
      }
      p {
        margin-bottom: 0px;
        font-size: 14px;
      }
    }
  }
}