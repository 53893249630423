@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.form {
  max-width: 500px;

  section {
    margin-bottom: $margin-md;
  }

  .row {
    display: flex;
    >div:nth-child(2) {
      margin-left: $margin-sm;
    }
  }

  .containerField {
    @include containerField;
    width: 100%;
    &.shortField {
      max-width: 130px;
    }
  }

  .companyFields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $margin-sm;
    max-width: 600px;
  }
  
  .tags {
    display: flex;
    >div {
      margin-bottom: $margin-xs;
      margin-right: $margin-xs;
    }
  }
  
}
