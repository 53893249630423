@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.edit {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  .header {
    width: 100%;
    margin-bottom: $margin-xs;
    padding-left: $margin-sm;

    @media screen and (min-width: $xl-screen) {
      padding-left: 0;
    }

    a {
      text-decoration: none;
      color: $text-light;
      cursor: pointer;
      svg {
        position: relative;
        margin-right: 5px;
        top: 2px;
      }
    }
  }
}