@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.list {
  @include page();

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-md;

    h1 {
      margin-bottom: 0;
      span {
        @include rowsCount();
      }
    }

    a {
      text-decoration: none;
    }


    >div:last-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      button {
        margin-left: $margin-md;
      }
    }
    
  }
  .filters {
    display: flex;
    >div {
      margin-right: $margin-md;
    }
  }
}