@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.account {
  position: relative;
  @include page();
  
  &:after {
    @include backgroundImage('../../assets/images/illu-tepik-teams.svg');
  }

  .header {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-md;

    .backward {
      font-size: 24px;
    }

    >div:last-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .createdAt {
        color: $text-light;
        margin: 0 $margin-md 0 auto;
      }
    }
    
  }

  .content {
    position: relative;
    z-index: 1;

    .form {
      max-width: 680px;
      .containerField {
        @include containerField;
      }
    }

  }

}