@import '../../styles/_variables.scss';

.usersTable {
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      th {
        position: relative;
        text-align: left;
        padding: $margin-sm 0 $margin-sm 0;
        border-bottom: solid 1px $border-grey;
        color: $text-light;
        cursor: pointer;
        padding-left: $margin-md;
        svg {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        .sortIcons {
          opacity: 0.3;
          >svg:first-child {
            transform: translateY(-80%);
          }
          >svg:nth-child(2) {
            transform: translateY(-20%);
          }
        }

      }
    }

    tbody {
      tr {
        cursor: pointer;
      }
      
      td {
        padding: $margin-sm 0;
        padding-left: $margin-md;

        .userCategories {
          padding: 0;
          margin: 0;
          list-style: none;
          li {
            display: inline-block;
            border-radius: 3px;
            padding: 3px $margin-xs;
            background-color: #E5F3EF;
            margin-right: $margin-xs;
            margin-bottom: $margin-xs;
          }
        }

      }
    }

  }
}