@import "../../styles/variables";
@import "../../styles/_mixins.scss";

.content {
  background-color: $white;
  border-radius: 10px;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
  width: 100%;
  .row {
    width: 100%;
    display: flex;
    padding: 6px 10px;
    text-decoration: none;
    @include transition;
    &:not(.label) {
      transition: margin 0.2s ease;
      cursor: grab;
      &.dragging {
        background-color: #f0f8ff;
        opacity: 0.8;
      }
      &.hovered {
        background-color: #eef7ff;
        margin-top: 20px; /* Adds space above the hovered row */
        transition: margin 0.2s ease;
      }
    }
    &:hover {
      &:not(.dragging) {
        .col {
          .delete {
            display: block;
          }
        }
      }
      &:not(.hovered) {
        .col {
          .delete {
            display: block;
          }
        }
      }
    }
    .col {
      width: calc(85% / 3);
      padding: 4px 8px;
      display: flex;
      align-items: center;
      position: relative;
      &.sort {
        cursor: pointer;
      }
      &.desc {
        .icon {
          transform: rotate(180deg)
        }
      }
      .icon {
        position: absolute;
        @include flex-center;
        color: $text-light;
        left: -10px;
      }
      &:first-child {
        width: 10%;
      }
      &:last-child {
        width: 5%;
      }
      p {
        margin-bottom: 0px;
        font-size: 14px;
        &.order {
          @include flex-center;
          @include bold;
          background-color: rgba($primary-color, 0.2);
          width: 24px;
          height: 24px;
          border-radius: 30px;
          font-size: 14px;
        } 
      }
      .condition {
        display: flex;
        align-items: center;
        border: 1px solid #EAE8E3;
        height: 28px;
        padding: 0px 3px;
        border-radius: 10px;
        &:hover {
          input {
            background-color: #f0f8ff;
          }
        }
        input {
          width: 28px;
          background-color: transparent;
          border: none;
          text-align: center;
          border-radius: 10px;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
          }
          -moz-appearance: textfield;
          &:focus {
            outline: none;
          }
        }
        p {
          @include bold;
          width: 28px;
          text-align: center;
        }
      }
      .tags {
        display: flex;
        p {
          font-size: 12px;
          margin: 0px;
          padding: 4px 8px;
          border-radius: 7px;
          margin-right: 6px;
        }
      }
      .delete {
        cursor: pointer;
        display: none;
      }
    }
    &.label {
      border-bottom: 1px solid #EAE8E3;
      .col {
        p {
          font-size: 14px;
          color: $text-light;
        }
      }
    }
  }
  .list {
    overflow-y: auto;
    max-height: calc(100vh - 210px);
  }
}