@import "../../styles/_variables.scss";

.navbar {
  position: absolute;
  z-index: 2;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  vertical-align: top;
  background-color: $bg-color;
  width: 60px;

  @media screen and (max-width: $xl-screen) {
    transition: width .4s ease-in-out;

    .navigation>ul:first-child {
      width: 236px;
    }

    &:not(.open) {

      .content {
        position: relative;
        .overlay {
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          width: 60px;
        }
      }

      .logo {
        display: flex;
        align-content: center;
        justify-content: center;
        >div {
          width: 48px;
          overflow: hidden;
          img {
            position: relative;
            left: -10px;
            height: 65px;
          }
        }
      }

      .navigation {
        position: relative;

        ul {
          pointer-events: none;
        }

        .itemText {
          display: none;
        }
      }

      .bottom {
        padding: 0;
        button {
          width: 100%;
          span:nth-child(1) {
            margin-left: 0;
          }
          span:nth-child(2) {
            display: none;
          }
        }
      }

    }
    &.open {
      width: 236px;
    }
  }

  @media screen and (min-width: $xl-screen) {
    position: relative;
    width: 236px;
  }

  .content {
    flex: 1;
    img {
      display: block;
      margin: $margin-sm auto;
      width: 111px;
    }
  }

  .content .navigation {
    padding: $margin-md;

    >ul>li {
      font-family: 'NunitoSans-Bold';
      .item {
        padding: $margin-xs 0;
      }

      >ul {
        padding-left: $margin-sm;
        .item {
          padding: 3px 0;
        }

        &.members {
          li {
            position: relative;
            padding-left: $margin-sm;
            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              display: block;
              width: 7px;
              height: 7px;
              transform: translateY(-50%);
            }
            &:nth-child(1):after {
              background-color: #4EABBF;
            }
            &:nth-child(2):after {
              background-color: #282828;
            }
          }
        }
        &.question {
          li {
            position: relative;
            padding-left: $margin-sm;
            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              display: block;
              width: 7px;
              height: 7px;
              transform: translateY(-50%);
            }
            &:nth-child(1):after {
              background-color: $primary-color;
            }
            &:nth-child(2):after {
              background-color: #4EABBF;;
            }
            &:nth-child(3):after {
              background-color: #282828;
            }
          }
        }

      }

    }

    .icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: $margin-xs;
      // display: inline-flex;
      // justify-content: center;
      // align-items: center;
      // width: 19px;
      // height: 19px;
    }

    .item {
      display: inline-block;
      font-family: "NunitoSans-Bold";
      text-decoration: none;
      cursor: pointer;
      &.active {
        color: $text-color;
        svg {
          fill: $text-color; 
        }
      }
      &:not(.active) {
        color: $text-light;
        svg {
          fill: $text-light; 
        }
      }
    }

    ul {
      list-style: none;
      padding: 0;
    }
  }
  
  .bottom {
    padding: $margin-sm;
    text-align: center;

    .user {
      border-bottom: solid 1px $border-grey;
      padding-bottom: $margin-sm;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        @media screen and (max-width: $xl-screen) {
          .name {
            display: none;
          }
        }
        .profilePicture {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 44px;
          height: 44px;
          border-radius: $radius;
          background-color: rgba($primary-color, 0.6);
          box-shadow: inset 0 0 0 2px $black;
          margin-right: $margin-xs;
          @media screen and (max-width: $xl-screen) {
            margin-right: 0px;
          }
          p {
            color: $white;
          }
        }

        p {
          font-family: "NunitoSans-Bold";
          margin: 0;
        }
      }
    }

    button {
      width: 200px;
    }
  }
}