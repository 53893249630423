@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.global {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: calc(100% - 60px);
  margin-left: 60px;
  height: 100%;
  vertical-align: top;
  padding: $margin-md $margin-md $margin-md 0;

  @media screen and (min-width: $xl-screen) {
    width: calc(100% - 236px);
    margin-left: 0;
    padding: $margin-md;

  }

  .container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    scrollbar-gutter: stable;
    @include scrollbar;
  }
}