@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.selectMultiple {
  h4 {
    margin-bottom: 8px;
    @include bold;
  }
  .containerField {
    @include containerField;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-end;
    >div:first-child {
      width: 100%;
    }
    >button {
      margin-left: $margin-md;
    }
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $margin-md;
    padding-bottom: calc(-1 * $margin-xs);
    >div {
      margin-bottom: $margin-xs;
      margin-right: $margin-xs;
    }
  }
}