@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.detail {
    @include page();
    display: flex;
    flex-direction: column;
    // height: 100%;

    .navigation {
        display: flex;
        align-items: center;
        margin-bottom: $margin-md;
        position: relative;
        .backward {
          position: absolute;
          top: 0px;
          display: inline-block;
          font-family: 'NunitoSans';
          text-decoration: none;
          color: $text-light;
          font-size: $regular;
          padding-right: $margin-sm;
          margin-right: $margin-lg;
          border-right: solid 1px $border-grey;
          svg {
            position: relative;
            top: 2px;
            margin-right: 5px;
          }
        }
        header {
          margin-top: 30px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          border-bottom: solid 1px $border-grey;
          .title {
            label {
              color: $text-light;
            }
            h1 {
              margin-bottom: 5px;
              font-size: 24px;
            }
          }
          .links {
            display: flex;
            a {
              display: block;
              font-family: 'NunitoSans-Bold';
              font-size: 18px;
              text-decoration: none;
              padding: 10px;
              border-bottom: solid 1px transparent;
              opacity: 0.4;
              &.active {
                border-bottom: solid 1px $primary-color;
                opacity: 1;
              }
            }
          }
        }
    }

}