@import "../../../styles/_variables.scss";

.signin-form {
  max-width: 400px;
  .form {
    margin-bottom: $margin-lg;
    .containerField {
      margin: 0 0 15px 0;
    }
    .message-error {
      color: $warn;
      font-size: $regular;
    }
  }

  .container-link {
    margin-bottom: $margin-md;
    p {
      font-size: $regular;
      color: $text-color;
      a {
        font-size: $regular;
        text-decoration: none;
        color: $primary-color;
      }
    }
  }

  .containerSubmit {

    .containerBtn {
      display: flex;
      align-items: center;
      > a {
        margin-top: 0;
        margin-left: $margin-sm;
      }
    }

    >a {
      display: block;
      position: relative;
      color: $text-color;
      margin-top: $margin-sm;
    }
  }
}
