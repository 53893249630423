@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.editTags {
  position: relative;
  @include page();

  &:after {
    @include backgroundImage('../../../assets/images/user-cagetories.svg');
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-md;

    .backward {
      font-size: 24px;
    }

    >div:last-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      button {
        margin-left: $margin-md;
      }
    }
    
  }

  .form {
    position: relative;
    z-index: 1;
  }

}