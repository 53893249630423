@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.form {
  max-width: 650px;
}

h3 {
  font-family: 'NunitoSans-Bold';
  font-size: $regular;
}

.containerField {
  @include containerField;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $margin-sm;
  max-width: 600px;
}

.check {
  margin-top: 20px;
}

.roles {
  display: flex;
  button {
    border: 1px solid #80C3AE;
    font-family: 'NunitoSans';
    color: $text-light;
    padding: 2px 8px;
    height: 30px;
    font-size: 14px;
    border-radius: 6px;
    margin-right: 10px;
    margin-bottom: 30px;
    &:not(.active) {
      background-color: transparent;
    }
    &.active {
      color: $white;
    }
  }
}
.tags {
  width: 70%;
}