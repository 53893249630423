@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.block {
  border-radius: $radius;
  background-color: $white;
  padding: $margin-xs $margin-sm;
  margin-top: 20px;
  .shared {
    display: flex;
    p {
      margin-bottom: 10px;
      margin-right: 10px;
      @include bold;
      span {
        color: $primary-color;
      }
    }
  }
  
}