@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_mixins.scss';

.listOrganisationMembers {

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-md;

    h2 {
      margin-bottom: 0;
      span {
        @include rowsCount();
      }
    }

    >div:last-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      a {
        text-decoration: none;
      }

      button {
        margin-left: $margin-md;
      }
    }
    
  }

  .filters {
    display: flex;
    margin-bottom: $margin-xs;
    >div {
      margin-right: $margin-md;
    }
  }


}