@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.advanced {
  margin-top: 20px;
  width: 100%;
  h3 {
    margin: 0px;
    color: $primary-color;
  }
  .options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    .option {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      label {
        font-size: 12px;
        color: $text-color;
      }
      .numbers {
        margin-top: 5px;
        display: flex;
        button {
          padding: 0px;
          width: 38px;
          height: 38px;
          margin-right: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          &.active {
            background-color: $primary-color;
            p {
              color: $white;
            }
          }
          p {
            margin: 0px;
          }
        }
      }
    }
  }
}