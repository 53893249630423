@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.field {

  label {
    @include label;
    + div {
      margin-top: 10px;
    }
  }

  .texteditor {
    padding: 0px;
    // height: 170px;
    // max-height: 170px;
    color: $white;

    &.discret {
      background-color: transparent;
    }
  }

  .count-characters {
    text-align: right;
    font-size: $regular;
    margin: 0;
    padding: $margin-sm;
    color: $text-light;
    span {
      color: $text-color;
    }
  }
}