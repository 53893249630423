@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.tepikTeam {
  @include page();

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-md;

    h1 {
      margin-bottom: 0;
      span {
        @include rowsCount();
      }
    }

    >div:last-child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      a {
        text-decoration: none;
      }

      button {
        margin-left: $margin-md;
      }
    }
    
  }
}