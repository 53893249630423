@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_mixins.scss';

.editMember {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: $radius;
  background-color: $white;
  min-height: 100%;
  &:after {
    @include backgroundImage('../../../../../assets/images/illu-orga-member.svg');
  }

  .save {
    position: absolute;
    z-index: 2;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dotLoader {
      @include dotLoader();
    }

    .createdAt {
      color: $text-light;
      margin: 0 $margin-md 0 auto;
    }
    
  }

  .content {
    position: relative;
    z-index: 1;
  }

  .delete {
    font-family: 'NunitoSans-Bold';
    color: $warn;
    text-decoration: underline;
    cursor: pointer;
    margin: $margin-md $margin-md 0 0;
  }

}