@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.create {
  position: relative;
  .header {
    margin-bottom: 0px;
    width: 100%;
    .backward {
      display: inline-block;
      font-family: 'NunitoSans';
      text-decoration: none;
      color: $text-light;
      font-size: $regular;
      padding-right: $margin-sm;
      margin-right: $margin-lg;
      border-right: solid 1px $border-grey;
      margin-bottom: 20px;
      svg {
        position: relative;
        top: 2px;
        margin-right: 5px;
      }
    }
  }
  .edit {
    position: relative;
    @media screen and (min-width: $xl-screen) {
      padding: 0px;
    } 
    .content {
      position: relative;
      z-index: 1;
    }
  
  }
}