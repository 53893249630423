@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.edit {
  position: relative;
  @media screen and (min-width: $xl-screen) {
    padding: 0px;
  }
  
  .content {
    position: relative;
    z-index: 1;
  }

}