@import "../../styles/variables";
@import "../../styles/mixins";

.container {
  display: flex;
  width: 100%;
  input {
    display: none;
  }
  label {
    display: block;
    width: 100%;
  }
  .media {
    display: block;
    position: relative;
    width: 100%;
    label {
      position: absolute;
      display: block;
      bottom: 10px;
      right: 10px;
      background-color: $primary-color;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      z-index: 1;
      cursor: pointer;
      img {
        width: 20px;
      }
    }
  }
}

