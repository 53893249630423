@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.resetPassword {
  height: 100%;
  background-image: url('../../assets/images/bg-signin.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;

  .container {
    height: 100%;
    @include content();
  }

  .container > div {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    vertical-align: top;
    &:first-child {
      width: 45%;
    }
    &:nth-child(2) {
      width: 55%;
    }
  }

  .container .form {
    width: 100%;
    max-width: 400px;
  }
}