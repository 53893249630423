@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins.scss';

.loader {
  @include flex-center;
  height: 100%;
  width: 100%;
}

.preview {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;

  .questions {
    @include page();
    padding: 0;
    width: calc(100% - 354px);
    overflow: auto;
  }
  
  .moreSettings {
    min-height: 100%;
    width: 354px;
    padding-left: $margin-sm;

    .switchMode {
      display: flex;
      margin-bottom: $margin-md;
      a {
        display: block;
        background-color: #EAEAEA;
        width: 50%;
        padding: $margin-sm $margin-md;
        height: 48px;
        text-decoration: none;
        text-align: center;
        &.actived {
          background-color: $primary-color;
          color: $white;
        }
        
        &:first-child {
          border-radius: $radius 0 0 $radius;
        }
        &:last-child {
          border-radius: 0 $radius $radius 0;
        }
      }
    }
  }
}